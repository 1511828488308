/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: top;
    font-family: 'Avenir Light', sans-serif !important;
    font-size: 13px !important;
    letter-spacing: 0 !important;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.clearfix {
    *zoom: 1;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix:after {
    clear: both;
}

.container .alpha {
    margin-left: 0px;
}

.container .omega {
    margin-right: 0px;
}

.row {
    /*margin-left: -30px;
  *zoom: 1;*/
}

.row:before,
.row:after {
    display: table;
    content: "";
    line-height: 0;
}

.row:after {
    clear: both;
}

[class*="grid_"] {
    float: left;
    min-height: 1px;

}

*,
:after,
:before {
    box-sizing: unset;
}

.container {
    width: 1170px;
}

.ss-menu {position: relative;}
.ss-menu .cross{   margin-top:7px;   margin-left:7px; display: inline-block; }
.ss-menu .cross:before, .ss-menu .cross:after {  content: '';  border-top: 1px solid #3E474F;  width: 7px;  display: block; transition: 0.3s; }
.ss-menu .cross:after {  transform: rotate(90deg);  margin-top: -1px; }
.ss-menu#active-button .cross:before { transform: rotate(180deg); }
.ss-menu#active-button .cross:after {  transform: rotate(0deg); } 


.ss-menu p.ssmenu {position: absolute; top: 25px; right: 10px;  display: flex;  flex-direction: column; gap: 6px;  z-index: 999; max-height:0; transition: max-height,.5s; background-color: white;  background-color: white; padding: 0 20px 20px;  opacity: .9; }
.ss-menu#active-button p.ssmenu {max-height: 400px; transition: max-height,1s;}
.ss-menu#active-button > div{ font-weight: bold;}

.home-head { padding: 30px 0; display:block; }
.home-logo {  display: flex;  justify-content: center; }
.home-content {  width: 100%;  display: flex;  justify-content: center;  flex-direction: column; align-items: center; gap: 45px; padding: 15vw 0; }
.home-menu { display:flex;  flex-direction: row;  gap: 105px; }

.home-menu input {  display: none; } 
.home-menu label { display: flex;  width: auto;  cursor: pointer;  user-select: none; }
.home-menu label div:first-child { width: auto;  line-height: 15px; }
.home-menu .cross{   margin-top:7px;   margin-left:7px; }
.home-menu .cross:before, .home-menu .cross:after {  content: '';  border-top: 1px solid #3E474F;  width: 7px;  display: block; transition: 0.3s; }
.home-menu .cross:after {  transform: rotate(90deg);  margin-top: -1px; }
.home-menu .content {  box-sizing: border-box;  margin: 20px 0 0 0;  max-height: 0;  overflow: hidden;  transition: max-height, .5s;  line-height: 25px; color:#aaa; }
.home-menu .content a { color:#aaa; }
.home-menu input:checked ~ .content { max-height: 400px;  transition: max-height, 1s; }
.home-menu input:checked ~ label .cross:before { transform: rotate(180deg); }
.home-menu input:checked ~ label .cross:after {  transform: rotate(0deg); }

















.look-mob {
    display: none;
}

.container-fluid {
    width: 100%;
}

.container-fluid img {
    width: 100%;
}

.images {
    /*border-right: 0.5px solid #aaa; border-bottom: 0.5px solid #aaa;*/
    position: relative;
    width: 100%;
    line-height: 0;
}

.hover {
    display: none;
}

.images:hover .hover {
    display: block;
}
.images:hover .hide-hover {  display: none;}
.look-menu {  display: flex;  flex-direction: column;  height: 150px; justify-content: space-evenly; padding-right: 38px;  text-align: right;}

.look-menu a { color: #000; text-decoration: none;}
a.unlink { color: #c5c5c5;}
a.unlink:visited { color: #e5e5e5; }

.grid-style-info { width: 80%;  padding: 0 10%;  text-align: left;  font-size: 13px; }
.grid-style-info p { margin-bottom:10px; line-height: 20px;}




.grid-single { float:left; width:100%; }
.grid-horizantal { border-top:1px solid #000 !important;  }
.grid-top { /*border-bottom:1px solid #000;*/ border-top:1px solid #000; }
.pos-abs {position: absolute !important;}

.grid-footer {height: 60px;  border-top: 1px solid #000;  border-bottom: 1px solid #000; display: grid; grid-template-columns: repeat(2,1fr); align-items: center; width:97.9%; padding:0 1%; }
.grid-footer p {line-height: 16px;}
.grid-footer div:nth-child(2) {  justify-self: flex-end; }






.image-text {
    text-align: left;
    height: 40px;
    line-height: 40px;
    position: absolute;
    bottom: 0;
    width: 97%;
    background: #fff;
    padding-left: 3%;
    overflow: hidden;
    border-top: 1px solid #000;
}

.fabric-image-text {
  text-align: left;
  height: auto;
/*  position: absolute;*/
  bottom: 0;
  width: 88%;
  background: #fff;
  padding: 25px 7% 45px 5%;
  overflow: hidden;
  border-top: 1px solid #000;
}

.fitguide24-image-text {
    text-align: left;
    height: auto;
  /*  position: absolute;*/
    bottom: 0;
    width: 92%;
    background: #fff;
    padding: 13px 4% 13px 4%;
    overflow: hidden;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    line-height: normal;
  }



.fabric-image-text h5 {font-weight: bold; margin-bottom: 15px; font-family: AvenirRomanFontDownload !important; }
.fabric-image-text p {line-height: 21px;}

.fit-text { text-align: left; height: 41px; line-height: 41px; position: static;   bottom: 0; width: 97% !important; background: #fff; padding-left: 3% !important; overflow: hidden; border-top: 1px solid #000; display: flex; justify-content: space-between;}

.fit-text a { color:#000; text-decoration:none; }



.lookbook-logo {
    padding-left: 38px;
}

.lookbook-head {
    height: 190px;
    padding-top: 30px
}

.lookbook-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 500px;
    gap: 26px;
    line-height: 17px;
    font-size: 13px;
}

.landing-container { display: flex; flex-direction: column; align-items: center; justify-content: center; height: 50vh; gap: 7vh; }
.button { width: 266px;  max-width: initial; background: transparent; color: #000; height: 50px; padding: 0; margin: 0; border: 1px solid #000; font-size: 13px; line-height: 50px; border-radius: 16px; text-transform: capitalize; text-align: center; }
.button a { text-decoration: none; color: #000; display: block; }

.ant-input-affix-wrapper {
    border: 1px solid #000;
    border-radius: 16px;
    height: 50px;
    width: 250px;
}

.ant-btn {
    border-radius: 16px;
    width: 250px;
    background: #000;
    border: 1px solid #000;
    height: 50px;
}
.ant-btn:not([disabled]):hover, .ant-btn:focus, .ant-btn:hover, .ant-btn:active {
    background: #333;
    border-color: #333;
}

/*
.preffix_12 {  margin-left: 1230px;}
.preffix_11 {  margin-left: 1130px;}
.preffix_10 {  margin-left: 1030px;}
.preffix_9 {  margin-left: 930px;}
.preffix_8 {  margin-left: 830px;}
.preffix_7 {  margin-left: 730px;}
.preffix_6 {  margin-left: 630px;}
.preffix_5 {  margin-left: 530px;}
.preffix_4 {  margin-left: 430px;}
.preffix_3 {  margin-left: 330px;}
.preffix_2 {  margin-left: 230px;}
.preffix_1 {  margin-left: 130px;}
*/

.grid-horizantal {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    float: left;
    width: 100%;
    border-top: 1px solid #000;
}

.grid-horizantal>div {
    border: 1px solid #000;
    border-left: 0;
    border-top: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*gap:120px;*/
}


.grid-horizantal2 {
    display: grid;
    grid-template-columns: 66.666% 33.333%;
    float: left;
    width: 100%;
    /*border-top:1px solid #000;*/
}

.grid-horizantal2>div {
    border: 1px solid #000;
    border-left: 0;
    border-top: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*gap:120px;*/
    border-bottom: 0;
  justify-content: space-between;
}

.grid-horizantal3 {
    display: grid;
    grid-template-columns: 33.333% 66.666%;
    float: left;
    width: 100%;
    /*border-top:1px solid #000;*/
}

.grid-horizantal3>div {
    border: 1px solid #000;
    border-left: 0;
    border-top: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*gap:120px;*/
    border-bottom: 0;
  justify-content: space-between;
}


  .grid-horizantal5 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    float: left;
    width: 100%;
  /*  border-top: 1px solid #000;*/
    position: relative;
  }
  
  .grid-horizantal5>div {
    border-right: 1px solid #000;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*gap:120px;*/
  }

  .grid-horizantal4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    float: left;
    width: 100%;
  /*  border-top: 1px solid #000;*/
    position: relative;
  }
  
  .grid-horizantal4>div {
    border-right: 1px solid #000;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*gap:120px;*/
  }
  .grid-horizantal4>div div {
    flex: 1 1;
}
.grid-horizantal4-image-text {
    text-align: left;
    height: 41px;
    line-height: 41px;
  /*  width: 98%;*/
    background: #fff;
    padding-left: 16px;
    overflow: hidden;
    justify-content: flex-start !important;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-right: 1px solid;
    font-size: 13px;
  }
  .grid-horizantal5-image-text {
    text-align: left;
    height: 41px;
    line-height: 41px;
  /*  width: 95%;*/
    background: #fff;
    padding-left: 16px;
    overflow: hidden;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    font-size: 13px;
  }
  .grid-horizantal6-image-text {
    text-align: left;
    height: 41px;
    line-height: 41px;
  /*  width: 98%;*/
    background: #fff;
    padding-left: 16px;
    overflow: hidden;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    font-size: 13px;
  }

  .grid-horizantal6>div>div {
      display: flex;
      flex-direction: row;
   }
  
  .grid-horizantal6>div>div>div {
      border-right: 1px solid #000;
  }

.container {
    margin-right: auto;
    margin-left: auto;
    *zoom: 1;
}

.container:before,
.container:after {
    display: table;
    content: "";
    line-height: 0;
}

.container:after {
    clear: both;
}

.grid_12 {
    width: 99.999%;
}

.grid_11 {
    width: 91.666%;
}

.grid_10 {
    width: 83.333%;
}

.grid_9 {
    width: 74.999%;
}

.grid_8 {
    width: 66.666%;
}

.grid_7 {
    width: 58.333%;
}

.grid_6 {
    width: 49.999%;
}

.grid_5 {
    width: 41.666%;
}

.grid_4 {
    width: 33.333%;
}

.grid_3 {
    width: 24.999%;
}

.grid_2 {
    width: 16.666%;
}

.grid_1 {
    width: 8.333%;
}


.border-bottom,.border-bottom-desk {  border-bottom: 1px solid #000;}
.border-top,.border-top-desk {  border-top: 1px solid #000;}
.border-left,.border-left-desk {  border-left: 1px solid #000;}
.border-right,.border-right-desk {  border-right: 1px solid #000;}

.border-bottom-mob {  border-bottom: 0px solid #000;}
.border-top-mob {  border-top: 0px solid #000;}
.border-left-mob {  border-left: 0px solid #000;}
.border-right-mob {  border-right: 0px solid #000;}

/****************Swiper******************/

.swiper {  width: 100% !important;    height: 100% !important;   }
.swiper-slide {  text-align: center !important;  font-size: 18px !important;  background: #fff !important;        /* Center slide text vertically */ display: -webkit-box !important;   display: -ms-flexbox !important;   display: -webkit-flex !important;   display: flex !important;  -webkit-box-pack: center !important;  -ms-flex-pack: center !important;   -webkit-justify-content: center !important;  justify-content: center !important;  -webkit-box-align: center !important;  -ms-flex-align: center !important;  -webkit-align-items: center !important; align-items: flex-start !important; width: 100% !important; flex-direction: column !important;  gap: 30px !important; text-align: left !important; }
.swiper-slide img {  display: block !important;  width: 100% !important;  height: 100% !important;  object-fit: cover !important;  }

.swiper-pagination-bullet { border: 1px solid #000 !important; background:transparent !important; opacity:1 !important; width:10px !important; height:10px !important; position: relative !important;    overflow: hiddin !important; }
/*.swiper-pagination-bullet-active {background:#000;}*/

.swiper-pagination-bullet-active:after {
    background: #000 !important;
    content:'' !important;
    transform: scale(0.7) !important;
}

.swiper-pagination-bullet-active:after,
.swiper-pagination-bullet-active:before
{
    content: "" !important;
    display: block !important;
    position: absolute !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 50% !important;
    border: 0px solid red !important;
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;

}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {bottom:12px !important;}

/*******************/



@media (min-width: 980px) and (max-width: 1199px) {


.grid-horizantal { /*grid-template-columns: repeat(2, 1fr);*/  border-top: 1px solid #000; }
  
}


@media (max-width: 1199px) {

  .fabric-image-text {
    padding: 2.5vw 7% 4vw 5%;
  }



}
@media (min-width: 768px) and (max-width: 979px) {
  
  
  /*.grid-horizantal { grid-template-columns: repeat(2, 1fr);  border-top: 1px solid #000; }*/
  
  .grid-horizantal5 {
  grid-template-columns: repeat(2, 1fr);
  }
    

  .grid-horizantal6 {
    grid-template-columns: repeat(1, 1fr);
  }
  }
  
  @media (max-width: 767px) {


    .home-content { padding: 300px 0; }
    .home-menu {flex-direction: column; gap: 20px;  align-items: center; }
    .home-menu label { justify-content: center;}
    .home-menu .content { text-align:center; }
    


  .lookbook-desc-kids {
      width: 86% !important;
      position: static !important;
      bottom: 37% !important;
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto !important;
      text-align: left !important;
      max-width: 390px !important;
      display: flex !important;
      gap: 17px !important;
      align-items: flex-start !important;
      padding: 26px 7% !important;
      line-height: 18px !important;
  }
  
  .grid-horizantal { 
  display:block; grid-template-columns: repeat(1, 1fr) !important; }
      
}

.select-menu {
    display: none !important;
}

@media (max-width: 767px) {

  .fabric-image-text {
    padding: 27px 7% 27px 5%;
  }
  
.border-bottom-desk {  border-bottom: 0px solid #000;}
.border-top-desk {  border-top: 0px solid #000;}
.border-left-desk {  border-left: 0px solid #000;}
.border-right-desk {  border-right: 0px solid #000;}
  



    .lookbook-desc { padding:5% 7% !important; position:static !important;  }
    .lookbook-desc.absolute { position:absolute !important; top:50%; bottom:auto; width: auto; } 

    
    .grid-menu>a:nth-child(even) { border-right: 0;}
    .grid-menu>a:nth-child(n+5) { border-bottom: 0;}
    
    
    
    .grid-footer{ display: flex; flex-direction: column;  gap: 30px;  height: 180px;  justify-content: center;}





.landing-container {  height: 150vh;  }
.grid-menu { display: grid;  grid-template-columns: repeat(2, 1fr); float: left; width: 100%; border-top: 1px solid #000; text-align: center; }

    .grid-menu>a {
        border: 1px solid #000;
        border-left: 0;
        border-top: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 50px;
        justify-content: center;
        text-decoration: none;
        color: #000;
    }

    a.unlink {
        color: #c5c5c5;
    }

    a.unlink:visited {
        color: #e5e5e5;
    }

    .grid-horizantal {
        grid-template-columns: repeat(1, 1fr);
        border-top: 0;
    }

    .grid-horizantal>div {
        border-right: 0;
        border-left: 0;
    }

    .grid-horizantal2>div {
        border-right: 0;
        border-left: 0;
    }

    .grid-horizantal2>div:first-child .images {
        border-bottom: 0;
    }

    .grid-horizantal3>div {
        border-right: 0;
        border-left: 0;
    }

    .grid-horizantal>div:first-child {
        border-top: 0;
    }

    .grid-horizantal>div:last-child {
        border-bottom: 0;
    }

    .grid-horizantal2 div .images {
        border-bottom: 1px solid #000;
    }

    .grid-horizantal2 div:last-child .images {
        border-bottom: 0px solid #000;
        border-top: 1px solid #000;
    }

    .grid-horizantal2 div div:last-child .images {
        border-bottom: 1px solid #000;
    }

    .grid-horizantal2 {
        grid-template-columns: repeat(1, 1fr);
    }

    .grid-horizantal3 {
        grid-template-columns: repeat(1, 1fr);
    }

    .grid-horizantal3 div:first-child .images {
        border-bottom: 1px solid #000;
    }

    .grid-horizantal3 div:first-child div:first-child {
        border-top: 1px solid #000;
    }

    .grid-horizantal2 div:first-child div:first-child {
        border-top: 1px solid #000;
    }

  .grid-horizantal4 {
  grid-template-columns: repeat(1, 1fr);
  }

.grid-horizantal5 {
grid-template-columns: repeat(2, 1fr);
}

.grid-horizantal6 {
  grid-template-columns: repeat(1, 1fr);
}


    .look-desk {
        display: none;
    }

    .look-mob {
        display: block;
    }

    .image-text {
        height: 40px;
        line-height: 40px;
    }


.color-white {color: white;}
.size17 {font-size: 17px !important;}
    .lookbook-head {
        height: auto;
        padding: 0;
    }

    .lookbook-desc {
        width: 92%;
        position: absolute;
        bottom: 4%;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: left;
        max-width: 390px;
        display: flex;
        align-items: center;
        padding: 0 4%;
    }

.grid-style-info { position:absolute; }

.fit-desc {
      width: 86%;
      position: absolute !important;
      bottom: 37%;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: left;
      max-width: 390px;
      display: flex;
      gap: 17px;
      align-items: flex-start;
      padding: 0 7%;
  }
    .lookbook-logo {
        position: absolute;
        top: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 80px;
        justify-content: space-around;
        margin: 0 auto;
        width: 100%;
        padding: 0;
        align-items: center;
    }

    [class*="grid_"] {
        float: none;
        width: 100%;
        margin-left: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /*
  .container {
    width: 420px;
    padding: 0 0px;
  }
  .row {
    margin-left: 0;
  }
  
  .grid_12 {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .select-menu {
    display: block !important;
  }*/
}

@media (max-width: 400px) {
  body {
    padding: 0;
  }

  .image-text {
    font-size: 3.2vw;
  }

  .lookbook-desc {
    font-size: 3.2vw;
  }

  .container {
    width: 300px;
    padding: 0 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .row {
    margin-left: 0;
  }
  
  .fit-text { font-size: 3.9vw;height: 13vw; line-height: 13vw; }
  


}
 